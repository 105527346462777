import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  payment_type = "monthly";

  onchange(){
    console.log(this.payment_type)
  }
  
  constructor() { }

  ngOnInit() {
  }

}
