import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public _opened = true;
  constructor(public db: AngularFirestore) { }

  toggleSidebar() {
    this._opened = true;
  }

  getChatCount() {

    return this.db.collectionGroup(`chats`).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  getNotificationCount(userId) {
    return this.db.collectionGroup(`notification`, ref => ref.where('userToId', '==', userId)).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.notificationId = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  markNotificationRead(path){
    return this.db.doc(path).update({
      isRead : true
    })
  }

 

}
