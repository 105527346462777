import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './components/root/root.component';
import { HomeComponent } from './home/home.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { PricingComponent } from './pricing/pricing.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ContactusComponent } from './contactus/contactus.component';
import { FindcontractorComponent } from './findcontractor/findcontractor.component';
import { ViewlistingComponent } from './viewlisting/viewlisting.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { TncComponent } from './tnc/tnc.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  
  {
      path: '',
      component: RootComponent,
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'home'
        },
        {
          path: 'home',
          component: HomeComponent,
        },
        {
          path: 'contractors',
          component: ContractorsComponent,
        },
        {
          path: 'contactus',
          component: ContactusComponent,
        },
        {
          path: 'pricing',
          component: PricingComponent,
        },
        {
          path: 'aboutus',
          component: AboutusComponent,
        },
        {
          path: 'termsandconditions',
          component: TncComponent,
        },
        {
          path: 'privacypolicy',
          component: PrivacyComponent,
        },
        {
          path: 'findcontractor',
          component: FindcontractorComponent,
        },
        {
          path: "viewlisting/:id",
          component: ViewlistingComponent,
        },
        {
          path: 'auth',
          loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
        },
        {
          path: 'dashboard',
          loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
        },
        {
          path: '**',
          component: NotfoundComponent
      }
      ]
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
