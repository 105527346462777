import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './blocks/footer/footer.component';
import { ShortFooterComponent } from './blocks/short-footer/short-footer.component';
import { HeaderComponent } from './blocks/header/header.component';
import { RouterModule } from '@angular/router';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    ShortFooterComponent,
    ScrollToBottomDirective
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    ShortFooterComponent
  ]
})
export class SharedModule { }
