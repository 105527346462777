import { Injectable } from '@angular/core';
import * as ElasticAppSearch from "@elastic/app-search-javascript";
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ListingService {

   client = ElasticAppSearch.createClient({
    searchKey: "search-732ygsggfgvnw5rormmo8sxc",
    endpointBase: "https://64c3bdc89fca46fe84a24872e17b412c.app-search.us-central1.gcp.cloud.es.io",
    engineName: "listings"
  });

  constructor(private db : AngularFirestore) { 
  }

  listcontractors(){
   return  this.db.collectionGroup('listing').valueChanges()
  }

  searchlistings(searchterm: string, city: string[], category: string[]) : Promise<any>{
    console.log(city, category)
    var options = {
      search_fields: { name: {} },
      result_fields: { 
        alternativenumber: { raw: {} },
        city: { raw: {} },
        contactnumber: { raw: {} },
        description: { raw: {} },
        email: { raw: {} },
        logourl: { raw: {} },
        name: { raw: {} },
        province: { raw: {} },
        slogan: { raw: {} },
        url: { raw: {} },
        id: { raw: {} }
       },
      ...(city && category) && {filters: {
        all: [
          { city: city },
          { category: category }
        ]
       }
      },
      ...(city) && { filters: {
        all: [
          { city: city },
        ]
      }
     },
     ...(category) && {filters: {
        all: [
          { category: category }
        ]
      }
     }
    };
    
    return this.client.search(searchterm, options)

  }
}



