import { Component, OnInit } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isLoggedin: any;
  user: any;
  accounttype: any;

  constructor(public router: Router,
    public afAuth: AngularFireAuth,
    private auth: AuthService) {
     
  }

  ngOnInit() {
    console.log("in footer");
        this.auth.isLoggedIn().subscribe(res => {
          this.isLoggedin = res ? true : false;
          console.log("in footer");
          if (this.isLoggedin == true) {
            this.afAuth.authState.subscribe(user => {
              if (user) {
                this.user = user;
                this.auth.getuserdata(user.uid).then((userdata: any) => {
                  this.accounttype = userdata.accounttype;
                  console.log("in footer");
                  console.log(this.accounttype);
                })
              }
            })
          }
        })

  }

}
