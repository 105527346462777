import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import { QuoteService } from 'src/app/shared/services/quote.service';
import { Observable } from 'rxjs';
import { Task } from '../interfaces/task';
import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  user: any;
  dashboardDataPO: any;
  dashboardDataContractor: any;
  dashboardAdminData: any;

  awardedProjectList: any;

  private tasksCollectionName = 'tasks';


  constructor(
    public db: AngularFirestore,
    public auth: AuthService,
    public quotesService: QuoteService,
    public afAuth: AngularFireAuth
  ) { }

  getProvinces() {
    return this.db.collection('provinces').valueChanges()
  }

  getCities() {
    return this.db.collection('cities', ref => { return ref.orderBy('name') }).valueChanges()
  }

  getAllCities() {
    return this.db.collection('cities').valueChanges({ idField: 'projectid' })
  }

  getMyProjects(uid) {
    return this.db.collection(`users/${uid}/projects`).valueChanges({ idField: 'projectid' })
  }

  getSingleProjectQuotes(uid, projectId) {
    return this.db.collection(`users/${uid}/projects/${projectId}/quotes`).valueChanges()
  }

  getQuoteQwnerDetails(uid) {
    return this.db.doc(`users/${uid}`).valueChanges()
  }

  getAllProjects() {
    return this.db.collectionGroup(`projects`, ref => ref.where('projectstatus', '==', 'new')).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectid = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;

          var poData = this.db.doc(`users/${data.projectownerId}`).valueChanges()
          poData.subscribe(poName => {

            data.projectOwnerDetails = poName
          })

          // console.log(data)
          return data;
        });
      })
    )
  }

  getAllUsers() {
    return this.db.collectionGroup(`users`).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectid = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  getMyProject(uid, projectid) {
    return this.db.doc(`users/${uid}/projects/${projectid}`).valueChanges()
  }

  getSingleProject(uid, projectid) {
    return this.db.doc(`users/${uid}/projects/${projectid}`).valueChanges()
  }

  getPODashboardData(uid) {
    this.dashboardDataPO = {};
    this.dashboardDataPO.totalProjects = 0;
    this.dashboardDataPO.totalActiveProjects = 0;
    this.dashboardDataPO.totalQuotes = 0;
    this.dashboardDataPO.acceptedQuotes = 0;
    this.dashboardDataPO.newQuotes = 0;
    this.dashboardDataPO.rejectedQuotes = 0;

    this.db.collection(`users/${uid}/projects`).valueChanges({ idField: 'projectid' })
      .subscribe(projects => {
        this.dashboardDataPO.totalProjects = projects.length
        //console.log("project count",projects.length)
        projects.forEach((project: any) => {
          this.db.collection(`users/${uid}/projects/${project.projectid}/quotes`).valueChanges()
            .subscribe(quotes => {
              this.dashboardDataPO.totalQuotes = this.dashboardDataPO.totalQuotes + 1
              quotes.forEach((quote: any) => {
                if (quote.quoteStatus == "accepted") {
                  this.dashboardDataPO.acceptedQuotes = this.dashboardDataPO.acceptedQuotes + 1
                } else if (quote.quoteStatus == "new") {
                  this.dashboardDataPO.newQuotes = this.dashboardDataPO.newQuotes + 1
                } else if (quote.quoteStatus == "rejected") {
                  this.dashboardDataPO.rejectedQuotes = this.dashboardDataPO.rejectedQuotes + 1

                }
                // console.log("quote status",quote.quoteStatus)

              });
            })
        });


        this.db.collection(`users/${uid}/acceptedprojects`).valueChanges()
          .subscribe(acceptedProjects => {
            this.dashboardDataPO.totalActiveProjects = acceptedProjects.length
            // console.log("project count",projects.length)
          });

        // console.log(this.dashboardDataPO)
      })
    return this.dashboardDataPO
    //console.log(this.dashboardDataPO)
  }



  getContractorDashboardData(uid) {
    this.dashboardDataContractor = {}

    this.dashboardDataContractor.totalActiveProjects = 0;
    this.dashboardDataContractor.totalQuotes = 0;
    this.dashboardDataContractor.newQuotes = 0;
    this.dashboardDataContractor.acceptedQuotes = 0;
    this.dashboardDataContractor.rejectedQuotes = 0;

    this.db.collection(`users/${uid}/awardedprojects`).valueChanges()
      .subscribe(awardedprojects => {
        this.dashboardDataContractor.totalActiveProjects = awardedprojects.length
      });

    this.quotesService.getMyProjectQuotes(uid).subscribe((quoteData: any[]) => {
      quoteData.forEach((quote: any) => {
        this.dashboardDataContractor.totalQuotes = this.dashboardDataContractor.totalQuotes + 1
        if (quote.quoteStatus == "accepted") {
          this.dashboardDataContractor.acceptedQuotes = this.dashboardDataContractor.acceptedQuotes + 1
        } else if (quote.quoteStatus == "new") {
          this.dashboardDataContractor.newQuotes = this.dashboardDataContractor.newQuotes + 1
        } else if (quote.quoteStatus == "rejected") {
          this.dashboardDataContractor.rejectedQuotes = this.dashboardDataContractor.rejectedQuotes + 1
        }
      });
    });
    return this.dashboardDataContractor

  }

  getAdminDashboardData(uid) {
    this.dashboardAdminData = {}

    this.dashboardAdminData.totalActiveProjects = 0;
    this.dashboardAdminData.totalQuotes = 0;
    this.dashboardAdminData.newQuotes = 0;
    this.dashboardAdminData.acceptedQuotes = 0;
    this.dashboardAdminData.rejectedQuotes = 0;

    this.db.collectionGroup(`users/${uid}/awardedprojects`).valueChanges()
      .subscribe(awardedprojects => {
        this.dashboardAdminData.totalActiveProjects = awardedprojects.length
      });

    this.quotesService.getMyProjectQuotes(uid).subscribe((quoteData: any[]) => {
      quoteData.forEach((quote: any) => {
        this.dashboardAdminData.totalQuotes = this.dashboardAdminData.totalQuotes + 1
        if (quote.quoteStatus == "accepted") {
          this.dashboardAdminData.acceptedQuotes = this.dashboardAdminData.acceptedQuotes + 1
        } else if (quote.quoteStatus == "new") {
          this.dashboardAdminData.newQuotes = this.dashboardAdminData.newQuotes + 1
        } else if (quote.quoteStatus == "rejected") {
          this.dashboardAdminData.rejectedQuotes = this.dashboardAdminData.rejectedQuotes + 1
        }
      });
    });
    return this.dashboardAdminData

  }

  getAwardedProjects(uid) {
    this.awardedProjectList = []

    this.db.collection(`users/${uid}/awardedprojects`).valueChanges()
      .subscribe(awardedprojects => {

        awardedprojects.forEach((awardedProject: any) => {
          this.getSingleProject(awardedProject.projectownerid, awardedProject.projectId).subscribe(res => {
            awardedProject.projectDetails = res;
          })
          this.awardedProjectList.push(awardedProject)
        });
      });
    console.log(this.awardedProjectList);
    return this.awardedProjectList;
  }

  // Project to Tasks
  getAllTasks(projectId) {
    return this.db.collectionGroup(`tasks`, ref => {
      return ref.where('projectId', '==', projectId)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.taskId = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  saveTask(task: Task): Promise<DocumentReference> {
    return this.db.collection(`${this.tasksCollectionName}`).add(task);
  }

  editTask(task: Task): Promise<void> {
    return this.db.collection(`${this.tasksCollectionName}`).doc(task.taskId).update(task);
  }
  editTaskStatus(task): Promise<void> {
    return this.db.collection(`${this.tasksCollectionName}`).doc(task.taskId).update(task);
  }

  deleteTask(idTask: string): Promise<void> {
    return this.db.collection(`${this.tasksCollectionName}`).doc(idTask).delete();
  }


  updateProjectSTatus(uid, projectId, status): Promise<void> {
    let updateStatus = {
      projectownerId: uid,
      projectstatus: status
    }
    return this.db.collection(`users/${uid}/projects`).doc(projectId).update(updateStatus);
  }

  changeProjectStatus(projectOwnerId, projectId, status) {

    return this.db.doc(`users/${projectOwnerId}/projects/${projectId}`).update({
      projectstatus: status
    })

  }


  updatePaymentProjectSTatus(uid, projectId, status): Promise<void> {
    let updateStatus = {
      projectownerId: uid,
      paystatus: status
    }
    return this.db.collection(`users/${uid}/projects`).doc(projectId).update(updateStatus);
  }

  getAllAdminProjects() {
    return this.db.collectionGroup(`projects`).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectid = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;

          var poData = this.db.doc(`users/${data.projectownerId}`).valueChanges()
          poData.subscribe(poName => {

            data.projectOwnerDetails = poName
          })

          return data;
        });
      })
    )
  }

  getAllAdminAwardedProjects() {
    return this.db.collectionGroup(`projects`, ref => ref.where('projectstatus', '==', 'awarded')).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectid = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  getAllAdminDisputedProjects() {
    return this.db.collectionGroup(`projects`, ref => ref.where('projectstatus', '==', 'disputed_project')).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectid = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }





}
