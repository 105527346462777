import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectsService } from '../shared/services/projects.service';
import { CategoriesService } from '../shared/services/categories.service';
import { QuoteService } from '../shared/services/quote.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ListingService } from '../shared/services/listing.service';


@Component({
  selector: 'app-findcontractor',
  templateUrl: './findcontractor.component.html',
  styleUrls: ['./findcontractor.component.scss']
})
export class FindcontractorComponent implements OnInit {


  projects$: Observable<any>;
  allcategories$: Observable<any[]>;
  allcities$: Observable<any[]>;
  category: string[];
  city: string[];

  listings = [];

  @ViewChild('keywordInput') keywordInput: ElementRef;
  keywordslist = [];


  constructor(
    public projectsService: ProjectsService,
    public categoriesService: CategoriesService,
    public quoteService: QuoteService,
    public route: Router,
    public afAuth: AngularFireAuth,
    public listingservice: ListingService) { }

  ngOnInit() {
    this.allcities$ = this.projectsService.getAllCities()
    this.allcategories$ = this.categoriesService.getAllCategories()
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.projects$ = this.projectsService.getAllProjects();
      }
    })

    this.listingservice.searchlistings("", null, null).then(resultList => {
      this.listings = []
      resultList.results.forEach(result => {
        let listing = {
          id: result.getRaw("id"),
          name: result.getRaw("name"),
          logo: result.getRaw("logourl"),
          slogan: result.getRaw("slogan"),
          province : result.getRaw("province"),
          city : result.getRaw("city"),
          email : result.getRaw("email")
        }
        this.listings.push(listing);
      });
    }).catch(error => {
      console.log(`error: ${error}`);
    });

  }



  addkeyword(keyword) {
    this.keywordInput.nativeElement.value = "";
    this.keywordslist.push(keyword)
  }

  removeKeyword(keyword) {
    var index = this.keywordslist.indexOf(keyword);
    this.keywordslist.splice(index, 1);
  }

  filtercity(city) {

console.log(city)

    this.listings = []
    console.log(this.city)
    this.listingservice.searchlistings("testing", this.city, this.category).then(resultList => {
      resultList.results.forEach(result => {
        let listing = {
          id: result.getRaw("id"),
          name: result.getRaw("name"),
          logo: result.getRaw("logourl"),
          slogan: result.getRaw("slogan"),
          province : result.getRaw("province"),
          city : result.getRaw("city"),
          email : result.getRaw("email")

        }
        this.listings.push(listing);
      });
    }).catch(error => {
      console.log(`error: ${error}`);
    });
  }

  filtercategory(categories) {
    console.log(categories)
    this.listings = []
    this.listingservice.searchlistings("testing", this.city, this.category).then(resultList => {
      resultList.results.forEach(result => {
        let listing = {
          id: result.getRaw("id"),
          name: result.getRaw("name"),
          logo: result.getRaw("logourl"),
          slogan: result.getRaw("slogan"),
          province : result.getRaw("province"),
          city : result.getRaw("city"),
          email : result.getRaw("email")
        }
        this.listings.push(listing);
      });
    }).catch(error => {
      console.log(`error: ${error}`);
    });
  }




}
