import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { Router ,NavigationEnd} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLoggedin: any;
  user:any;
  accounttype:any;

  constructor(public menu : MenuService, 
    public router: Router, 
    public afAuth: AngularFireAuth,
    private auth : AuthService) { 
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.auth.isLoggedIn().subscribe(res =>{
            this.isLoggedin = res ? true : false;
            if(this.isLoggedin == true){
              this.afAuth.authState.subscribe(user => {
                if (user) {
                  this.user = user;
                  this.auth.getuserdata(user.uid).then((userdata: any) => {
                    this.accounttype = userdata.accounttype;
                    console.log("in header");
                    console.log(this.accounttype);
                  })
                }
              })
            }
          })
        }
     });
    }


  ngOnInit() {
    this.auth.isLoggedIn().subscribe(res =>{
      this.isLoggedin = res ? true : false;
    })
  }

  logout(){
    this.auth.logout().then(()=>{
      window.location.reload()
    })
  }

}
