import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    public db: AngularFirestore,   
    public auth : AuthService
  ){
    
  }


  getMainCategories(){
    return this.db.collection("categories",  ref => { return ref.where('parent', '==', null)}).valueChanges({idField : "categoryId"})
  }

  getSubCategories(parentname){
    return this.db.collection("categories",  ref => { return ref.where('parentname', '==', parentname)}).valueChanges({idField : "categoryId"})
  }

  getAllCategories(){
    return this.db.collection("categories").valueChanges({idField : "categoryId"})
  }

  setCategories(){
    this.pest_control.forEach(x =>{

      let cat = 
      {
        parentname : "Pest control", 
        parentslug : "pest-control",
        parentid : "nGyvuOKTLRMpz5MPSGG9",
        ...x
        }

      console.log(cat)
      
      this.db.collection("categories").add({
        ...cat
      }).then(res =>{
        this.db.doc(`categories/${res.id}`).update({
          categoryId : res.id
        })
      })
    })
 
  }


//{slug : "building-construction",name : "BUILDING & CONSTRUCTION"},

building_construction =[
    {slug : "maintenance-repairs-remedial-work",name : "Maintenance - Repairs - Remedial work"},
    {slug : "home-property-makeover",name : "Home - Property makeover "},
    {slug : "extensions-additions-alterations",name : "Extensions - Additions - Alterations "},
    {slug : "construction-new-buildings",name : "Construction - New buildings"},
    {slug : "construction-roads",name : "Construction - Roads "},
    {slug : "construction-bridges-dams",name : "Construction - Bridges & Dams  "},
    {slug : "project-management",name : "Project management "},
    {slug : "all-building-civil-engineering-contractors",name : "All building & civil engineering contractors "},
]


//{slug : "handyman-services",name : "HANDYMAN SERVICES"},

handyman_services = [
{slug : "maintenance-repairs-remedial-work",name : "Maintenance - Repairs - Remedial work"},
{slug : "bricklayer-plasterer",name : "Bricklayer - Plasterer "},
{slug : "welder",name : "Welder "},
{slug : "tiler",name : "Tiler "},
{slug : "painter",name : "Painter "},
{slug : "carpenter",name : "Carpenter "},
{slug : "all-handyman-service-providers",name : "All handyman service providers "},
]


//{slug : "plumbing",name : "PLUMBING"},

plumbing = [
{slug : "maintenance-repairs-remedial-work",name : "Maintenance - Repairs - Remedial work"},
{slug : "emergency-24-hour-plumbing",name : "Emergency - 24 hour plumbing "},
{slug : "plumbing-new-developments",name : "Plumbing - New developments "},
{slug : "new-geyser-supply-installations",name : "New geyser supply & installations "},
{slug : "solar-geysers-supply-installations",name : "Solar geysers supply & installations"},
{slug : "all-plumbing-contractors",name : "All plumbing Contractors "},
]


//{slug : "electrical",name : "ELECTRICAL "},
electrical = [
{slug : "installations-maintenance-repairs",name : "Installations - Maintenance - Repairs "},
{slug : "emergency-24-hour-electrician",name : "Emergency - 24 hour electrician"},
{slug : "electrical-new-developments",name : "Electrical - New developments"},
{slug : "alternate-power-solutions",name : "Alternate power solutions "},
{slug : "solar-power-solutions",name : "Solar power solutions"},
{slug : "generators-inverters",name : "Generators - Inverters "},
{slug : "electric-fencing",name : "Electric fencing"},
{slug : "cctv",name : "CCTV"},
{slug : "intercom",name : "Intercom"},
{slug : "alarm",name : "Alarm"},
{slug : "automation",name : "Automation "},
{slug : "satellite-dish-installation",name : "Satellite dish installation	"},
{slug : "all-electrical-electrical-engineering-contractors",name : "All electrical & electrical engineering contractors "},
]


//{slug : "painting",name : "PAINTING "},
painting =  [
{slug : "maintenance-remedial-work",name : "Maintenance - Remedial work"},
{slug : "damp-specialist",name : "Damp specialist "},
{slug : "waterproofing",name : "Waterproofing"},
{slug : "all-specialist-painting-contractors",name : "All specialist painting contractors "},
]



//{slug : "flooring",name : "FLOORING "},
flooring = [
{slug : "maintenance-repairs-remedial-work",name : "Maintenance - Repairs - Remedial work"},
{slug : "tiling",name : "Tiling "},
{slug : "laminate-floors",name : "Laminate floors "},
{slug : "vinyl-floors",name : "Vinyl floors "},
{slug : "wooden-floors",name : "Wooden floors"},
{slug : "carpets",name : "Carpets"},
{slug : "synthetic-grass",name : "Synthetic grass"},
{slug : "all-specialist-flooring-contractors",name : "All specialist flooring contractors "},
]

//{slug : "roofing",name : "ROOFING"},
roofing = [
{slug : "maintenance-repairs-remedial-work",name : "Maintenance - Repairs - Remedial work"},
{slug : "extensions-additions-alterations",name : "Extensions - Additions - Alterations"},
{slug : "waterproofing",name : "Waterproofing "},
{slug : "thatch-roofs",name : "Thatch roofs"},
{slug : "new-roofs",name : "New roofs"},
{slug : "all-specialist-roofing-contractors",name : "All specialist roofing contractors "},
]

//{slug : "walling",name : "WALLING "},
walling = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "extensions-additions-alterations",name : "Extensions - Additions - Alterations"},
{slug : "precast-walls",name : "Precast walls "},
{slug : "palisade-fencing",name : "Palisade fencing"},
{slug : "electric-fencing",name : "Electric fencing"},
{slug : "razor-wire",name : "Razor wire"},
{slug : "all-specialist-walling-contractors",name : "All specialist walling contractors "},
]


//{slug : "paving-tarring",name : "PAVING & TARRING "},
paving_tarring = [
{slug : "paving",name : "Paving "},
{slug : "concrete-surfaces",name : "Concrete surfaces "},
{slug : "tar-asphalt-surfaces",name : "Tar & Asphalt surfaces "},
{slug : "road-works",name : "Road works "},
{slug : "all-specialist-paving-tarring-contractors",name : "All specialist paving & tarring contractors"},
]

//{slug : "metal-works",name : "METAL WORKS "},
metal_works = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "steel-structures",name : "Steel structures "},
{slug : "palisade-fencing",name : "Palisade fencing "},
{slug : "custom-carports",name : "Custom carports "},
{slug : "burglar-guards",name : "Burglar guards "},
{slug : "security-gates",name : "Security gates"},
{slug : "expandable-gates",name : "Expandable gates"},
{slug : "balustrades",name : "Balustrades"},
{slug : "railings",name : "Railings "},
{slug : "jungle-gyms",name : "Jungle gyms"},
{slug : "commercial-industrial",name : "Commercial - Industrial "},
{slug : "all-specialist-steel-fabrication-contractors",name : "All specialist steel fabrication contractors"},
]

//{slug : "glass-aluminium",name : "GLASS & ALUMINIUM "},
glass_aluminium = [
{slug : "maintenance-repairs-replacements",name : "Maintenance - Repairs - Replacements "},
{slug : "partitions",name : "Partitions"},
{slug : "doors",name : "Doors"},
{slug : "shower-doors",name : "Shower doors "},
{slug : "windows",name : "Windows"},
{slug : "balustrades",name : "Balustrades"},
{slug : "all-specialists-glass-aluminium-contractors",name : "All specialists glass & aluminium contractors"},
]


//{slug : "awnings-carports",name : "AWNINGS, CARPORTS, ETC"},
awnings_carports = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "awnings",name : "Awnings "},
{slug : "carports",name : "Carports "},
{slug : "shadeports",name : "Shadeports "},
{slug : "blinds",name : "Blinds "},
{slug : "custom-carports",name : "Custom carports"},
{slug : "all-specialist-weather-protection-contractors",name : "All specialist weather-protection contractors"},
]

//{slug : "carpentry",name : "CARPENTRY"},
carpentry = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "general-carpentry",name : "General carpentry "},
{slug : "kitchen-specialists",name : "Kitchen specialists "},
{slug : "built-in-cupboards",name : "Built-in cupboards"},
{slug : "furniture",name : "Furniture"},
{slug : "outdoor-furniture",name : "Outdoor furniture "},
{slug : "wendy-houses",name : "Wendy houses "},
{slug : "wooden-homes-buildings",name : "Wooden homes/buildings"},
{slug : "wood-cabins",name : "Wood cabins "},
{slug : "all-specialist-carpentry-contractors",name : "All specialist carpentry contractors "},
]

//{slug : "shopfitting",name : "SHOPFITTING"},
shopfitting = [
{slug : "comprehensive-shopfitting-solutions",name : "Comprehensive shopfitting solutions"},
{slug : "office-furniture",name : "Office furniture "},
{slug : "exhibition-stands",name : "Exhibition stands "},
{slug : "signage",name : "Signage "},
{slug : "all-specialist-shopfitting-contractors",name : "All specialist shopfitting contractors"},
]

//{slug : "security-solutions",name : "SECURITY SOLUTIONS"},
security_solutions = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "expandable-gates",name : "Expandable gates "},
{slug : "security-gates",name : "Security gates"},
{slug : "burglar-guards",name : "Burglar guards "},
{slug : "electric-fencing",name : "Electric fencing "},
{slug : "razor-wire",name : "Razor wire"},
{slug : "cctv",name : "CCTV "},
{slug : "intercom",name : "Intercom "},
{slug : "alarm",name : "Alarm"},
{slug : "access-control-system",name : "Access control system "},
{slug : "boom-gates",name : "Boom gates"},
{slug : "spike-barriers",name : "Spike barriers"},
{slug : "all-specialist-security-solutions-contractors",name : "All specialist security solutions contractors "},
]

//{slug : "air-conditioning-heating",name : "AIR-CONDITIONING & HEATING"},
air_conditioning_heating = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "air-conditioner-supply-installation",name : "Air-conditioner supply & installation"},
{slug : "ducting",name : "Ducting "},
{slug : "ventilation",name : "Ventilation "},
{slug : "extraction",name : "Extraction "},
{slug : "heating-solutions",name : "Heating solutions "},
{slug : "all-specialist-air-conditioning-heating-contractors",name : "All specialist air-conditioning & heating contractors "},
]

//{slug : "pool-services",name : "POOL SERVICES "},
pool_services = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "fiberglass-pools",name : "Fiberglass pools "},
{slug : "concrete-pool-construction",name : "Concrete pool construction"},
{slug : "pool-covers",name : "Pool covers "},
{slug : "balustrades",name : "Balustrades "},
{slug : "water-features",name : "Water features "},
{slug : "all-specialist-pool-contractors",name : "All specialist pool contractors "},
]


//{slug : "jacuzzis-spas-saunas-etc",name : "JACUZZIS, SPAS, SAUNAS, ETC"},
jacuzzis = [
{slug : "maintenance-repairs",name : "Maintenance - Repairs"},
{slug : "jacuzzis",name : "Jacuzzis "},
{slug : "spas",name : "Spas"},
{slug : "luxury-baths",name : "Luxury baths "},
{slug : "saunas",name : "Saunas "},
{slug : "covers",name : "Covers "},
{slug : "all-specialist-jacuzzi-spa-sauna-contractors",name : "All specialist jacuzzi, spa & sauna contractors"},
]

//{slug : "gas-gas-related",name : "GAS & GAS RELATED"},
gas = [
{slug : "installations-maintenance-repairs",name : "Installations - Maintenance - Repairs"},
{slug : "gas-supply",name : "Gas supply "},
{slug : "gas-appliances-equipment",name : "Gas appliances & equipment "},
{slug : "gas-fireplaces",name : "Gas fireplaces"},
{slug : "gas-braais",name : "Gas braais "},
{slug : "all-gas-specialist",name : "All gas specialist "},
]


//{slug : "garden-services",name : "GARDEN SERVICES "},
garden = [
{slug : "maintenance-remedial",name : "Maintenance - Remedial"},
{slug : "landscape-architecture",name : "Landscape architecture "},
{slug : "tree-felling",name : "Tree felling"},
{slug : "all-specialist-garden-services-contractors",name : "All specialist garden services contractors"},
]

//{slug : "design-architectural",name : "DESIGN & ARCHITECTURAL "},
architectural = [
{slug : "architect",name : "Architect "},
{slug : "draughtsperson",name : "Draughtsperson"},
{slug : "plan-drawer",name : "Plan drawer "},
{slug : "interior-designer-decorator",name : "Interior designer/decorator"},
{slug : "all-design-architectural-contractors",name : "All design & architectural contractors"},
]


//{slug : "locksmiths",name : "LOCKSMITHS"},
locksmiths = [
{slug : "residential-commercial-industrial",name : "Residential - Commercial - Industrial "},
{slug : "emergency-24-hour-locksmiths",name : "Emergency - 24 hour locksmiths "},
{slug : "key-solutions",name : "Key solutions "},
{slug : "auto",name : "Auto "},
{slug : "all-expert-locksmiths",name : "All expert locksmiths "},
]

//{slug : "pest-control",name : "PEST CONTROL "},
pest_control = [
{slug : "comprehensive-pest-control",name : "Comprehensive pest control "},
{slug : "bird-control",name : "Bird control "},
{slug : "bird-proofing",name : "Bird proofing "},
{slug : "all-pest-control-contractors",name : "All pest control contractors "},
]




}
