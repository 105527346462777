import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, GoogleMap } from '@angular/google-maps';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { SliderModule } from 'angular-image-slider';

@Component({
  selector: 'app-viewlisting',
  templateUrl: './viewlisting.component.html',
  styleUrls: ['./viewlisting.component.scss']
})
export class ViewlistingComponent implements OnInit {


  @ViewChild(MapInfoWindow) map: GoogleMap;

  center;
  zoom = 10;
  display?: google.maps.LatLngLiteral;
  options = {
    mapTypeControl: false,
  };


  listing$: Observable<any>
  gallary$: Observable<any>
  user: any;

  carouselOptions: OwlOptions = {
    items: 3,
    nav: false,
    dots: false,
    loop: true,
    margin: 10,
    navText: ["", ""],
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    autoplay: false,
    autoplayHoverPause: false,
    slideTransition: "linear"
  };

  private sub: any;

  constructor(
    public db: AngularFirestore,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth) { }

  ngOnInit() {


    this.sub = this.route.params.subscribe(params => {
      this.listing$ = this.db.doc(`users/${params.id}/listing/listing`).valueChanges()
      this.gallary$ = this.db.collection(`users/${params.id}/listing/listing/galleryfiles`).valueChanges({ idField: 'fileid' })
      
    });

  }
}





