import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { first } from "rxjs/operators";
import { Userprofile } from 'src/app/shared/interfaces/userprofile';
import { UserBankprofile } from 'src/app/shared/interfaces/bankdetail';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';



@Injectable({
  providedIn: "root",
})
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFirestore
  ) { }

  ngOnInit() { }

  public logout(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.afAuth
        .signOut().then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  public signup(value: { email: string; password: string }): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            resolve(res);
            this.verifyemail()
            //console.log(res);
          },
          (err) => reject(err)
        );
    });
  }

  public emailLogin(value: { email: string; password: string }): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth.
        signInWithEmailAndPassword(value.email, value.password).then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  public resetpassword(email): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.afAuth.
        sendPasswordResetEmail(email).then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }


  public googleLogin(): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(res => {
          resolve(res);
        }, err => reject(err))

    })
  }

  public facebookLogin(): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(res => {
          resolve(res);
        }, err => reject(err))

    })
  }

  public twitterLogin(): Promise<firebase.auth.UserCredential> {
    return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
      this.afAuth
        .signInWithPopup(new firebase.auth.TwitterAuthProvider())
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  public verifyemail(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.afAuth.currentUser.then((user) => {
        return user.sendEmailVerification()
      }).then((res) => {
        resolve(res);
      }, (err) => reject(err)
      );
    });
  }

  public getUser(): Promise<firebase.User> {
    return this.afAuth.authState.toPromise();
  }

  public isLoggedIn(): Observable<firebase.User> {
    return this.afAuth.authState.pipe(first());
  }


  public async saveuser(userObj, additionalData) {

    console.log(userObj)
    const userRef: AngularFirestoreDocument<any> = this.db.doc(`users/${userObj.user.uid}`);
    // const usernameRef: AngularFirestoreDocument<any> = this.db.doc(`usernames/${userObj.user.uid}`);

    const userData: firebase.User = {
      uid: userObj.user.uid,
      fullname: additionalData.fullname,
      contact: additionalData.contact,
      email: userObj.user.email,
      emailVerified: userObj.user.emailVerified,
      accountStatus: "active",
      isListingComplete: false,
      isProfileComplete: false,
      isAccrediationComplete: false,
      datecreated: firebase.firestore.FieldValue.serverTimestamp(),
      ...additionalData,
    }

    await userRef.set(userData, {
      merge: true
    });

    // return usernameRef.set({ userid: userObj.user.uid, username: additionalData.username }, {
    //   merge: true
    // });
  }

  public async saveuserSocial(userObj, additionalData) {

    console.log(userObj)
    const userRef: AngularFirestoreDocument<any> = this.db.doc(`users/${userObj.user.uid}`);
    //const usernameRef: AngularFirestoreDocument<any> = this.db.doc(`usernames/${userObj.user.uid}`);

    if(userObj.additionalUserInfo.isNewUser){
      const userData: firebase.User = {
        uid: userObj.user.uid,
        fullname: userObj.additionalUserInfo.profile.name,
        contact: "0000000000",
        email: userObj.additionalUserInfo.profile.email,
        emailVerified: userObj.user.emailVerified,
        accountStatus: "active",
        isListingComplete: false,
        isProfileComplete: false,
        isAccrediationComplete: false,
        datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        ...additionalData,
      }
  
      await userRef.set(userData, {
        merge: true
      });
    }else{
      const userData: firebase.User = {
        uid: userObj.user.uid,
        fullname: userObj.additionalUserInfo.profile.name,
        email: userObj.additionalUserInfo.profile.email,
        emailVerified: userObj.user.emailVerified,
        datecreated: firebase.firestore.FieldValue.serverTimestamp(),
        ...additionalData,
      }
  
      await userRef.set(userData, {
        merge: true
      });
    }
    

    

    // return usernameRef.set({ userid: userObj.user.uid, username: additionalData.username }, {
    //   merge: true
    // });
  }

  public getuserdata(userid) {
    return this.db.doc(`users/${userid}`).valueChanges().pipe(first()).toPromise()
  }

  public getuserprofiledata(userid) {
    return this.db.collectionGroup(`profile`, ref => {
      return ref.where('userId', '==', userid)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.profileId = a.payload.doc.id;
          return data;
        });
      })
    )
  }

  public getUserListing(userid) {
    return this.db.doc(`users/${userid}/listing/listing`).valueChanges().pipe(first()).toPromise()
  }

  public errormessages(error) {
    var message;
    switch (error.code) {
      case "auth/wrong-password": {
        message =
          "Invalid credentials entered. Re-enter the required logon information.";
        break;
      }
      case "auth/user-not-found": {
        message =
          "Invalid credentials entered. Re-enter the required logon information.";
        break;
      }
      case "auth/network-request-failed": {
        message =
          "Network error, Please check that you have a healthy connection.";
        break;
      }
      case "auth/invalid-email": {
        message = "The email address you entered is invalid";
        break;
      }

      case "auth/user-disabled": {
        message = "Your sser has been disabled. Please contact Support";
        break;
      }
      case "auth/too-many-requests": {
        message = error.message;
        break;
      }

      case "auth/email-already-in-use": {
        message = "Email address already exists";
        break;
      }
      case "auth/invalid-email": {
        message = "Invalid email address";
        break;
      }
      case "auth/weak-password": {
        message =
          "Your password is too weak, please choose a stronger password";
        break;
      }
      default: {
        message = "Error: " + error.message;
        break;
      }
    }

    return message;
  }

  async updateUserProfile(userprofile: Userprofile) {

    if (userprofile.profileId == '0') {
      return this.db.collection(`profile`).add(userprofile);
    } else {
      return this.db.collection(`profile`).doc(userprofile.profileId).update(userprofile);
    }
  }

  async updateUserDetails(userDetails: any, userid) {
    console.log("update userdetail");
    console.log(userDetails);
    return this.db.collection(`users`).doc(userid).update(userDetails).then(()=>{
      return this.db.doc(`users/${userid}`).update({
        isProfileComplete : true
      })})

  }

  async updateUserBankProfile(UserBankprofile: UserBankprofile) {

    if (UserBankprofile.profileId == '0') {
      return this.db.collection(`profile`).add(UserBankprofile);
    } else {
      return this.db.collection(`profile`).doc(UserBankprofile.profileId).update(UserBankprofile);
    }
  }

  getAllAdminNotification() {
    return this.db.collectionGroup(`notification`, ref => {
      return ref.where('isAdmin', '==', true)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.notificationId = a.payload.doc.id;
          return data;
        });
      })
    )
  }




  // updateUserProfile(userprofile : Userprofile){

  //   if(userprofile.profileId=='0'){
  //     return this.db.collection(`users/${userprofile.userId}/profile`).add(userprofile);
  //   }else{
  //     return this.db.collection(`users/${userprofile.userId}/profile`).doc(userprofile.profileId).update(userprofile);
  //   }

  // }

}
