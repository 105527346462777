import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Task } from 'src/app/shared/interfaces/task';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  currentproject: any;
  activeContractorQuotes: any;
  quotesImages:any[];

  constructor(public db: AngularFirestore) { }

  get getcurrentproject() {
    return this.currentproject
  }

  setcurrentproject(currentproject) {
    this.currentproject = currentproject
  }

  getProjectQuotes(uid, projectid) {
    return this.db.collection(`users/${uid}/projects/${projectid}/quotes`, ref => { return ref.where('quoteStatus', '==', "new") }).valueChanges({ idField: 'quotesId' })
    // return  this.db.collection(`users/${uid}/projects/${projectid}/quotes`,  ref =>  ref.where('quoteStatus', '==', "approved").where('quoteStatus', '==', "new")).valueChanges({idField : 'quotesId'})
  }

  getMyProjectQuotes(userid) {
    return this.db.collectionGroup(`quotes`, ref => {
      return ref.where('quotefrom.quoterid', '==', userid)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectId = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  getPOQuotesOnProjects(userid) {
    return this.db.collectionGroup(`quotes`, ref => {
      return ref.where('quotefrom.quoterid', '==', userid)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map((a: any) => {
          const data = a.payload.doc.data();
          data.projectId = a.payload.doc.id;
          data.path = a.payload.doc.ref.path;
          return data;
        });
      })
    )
  }

  getContractorActiveQuotes(uid) {
    this.activeContractorQuotes = [];
    this.getMyProjectQuotes(uid).subscribe(activeQuotes => {
      activeQuotes.forEach((activeQuote: any) => {


        this.quotesImages = []
        var images = this.db.collection(`${activeQuote.path}/files`).valueChanges()

        images.subscribe((quoteImages: any[]) => {
          if (quoteImages.length > 0) {
            quoteImages.forEach((image: any) => {
              this.quotesImages.push(image)
              // console.log(image)
            });
          }
        });

        this.getSingleProject(activeQuote.projectpath).subscribe(res => {
          activeQuote.projectDetails = res;
        })

        activeQuote.quoteImages = this.quotesImages;

        // console.log(activeQuote)
        this.activeContractorQuotes.push(activeQuote)

      });

    });
    return this.activeContractorQuotes;

  }

  getSingleProject(projectPath) {
    return this.db.doc(`${projectPath}`).valueChanges()
  }




}
