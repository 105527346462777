import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RootComponent } from './components/root/root.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './shared/services/auth.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { GoogleMapsModule } from '@angular/google-maps';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { PricingComponent } from './pricing/pricing.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactusComponent } from './contactus/contactus.component';
import { FindcontractorComponent } from './findcontractor/findcontractor.component';
import { Ng5SliderModule } from 'ng5-slider';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxSelectModule } from 'ngx-select-ex';
import { ViewlistingComponent } from './viewlisting/viewlisting.component';
import { NgxMaskModule } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TncComponent } from './tnc/tnc.component';
// import { DateAgoPipe } from './pipes/date-ago.pipe';


@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    HomeComponent,
    ContractorsComponent,
    PricingComponent,
    NotfoundComponent,
    ContactusComponent,
    FindcontractorComponent,
    ViewlistingComponent,
    AboutusComponent,
    PrivacyComponent,
    TncComponent
    // DateAgoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({
      synchronizeTabs : true
    }),
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    GoogleMapsModule,
    ToastrModule.forRoot(),
    FormsModule,
    SharedModule,
    Ng5SliderModule,
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSelectModule,
    NgxMaskModule.forRoot(),
    CarouselModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
